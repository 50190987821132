import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["quote"]

	static classes = ["ready", "hidden"]

	static values = {
		interval: {
			type: Number,
			default: 5000
		}
	}

	connect() {
		this.currentIndex = 0
		this.interval = setInterval(this.changeQuote.bind(this), this.intervalValue)

		setTimeout(() => {
			this.quoteTargets.forEach(quote => {
				quote.classList.add(this.readyClass)
			})
		}, this.intervalValue / 2)
	}

	disconnect() {
		clearInterval(this.interval)
	}

	changeQuote() {
		this.currentQuote.classList.add(this.hiddenClass)
		this.nextQuote.classList.remove(this.hiddenClass)
		this.currentIndex = this.nextIndex
	}

	get currentQuote() {
		return this.quoteTargets[this.currentIndex]
	}

	get nextQuote() {
		return this.quoteTargets[this.nextIndex]
	}

	get nextIndex() {
		if (this.currentIndex + 1 > this.quoteTargets.length - 1) {
			return 0
		} else {
			return this.currentIndex + 1
		}
	}
}
