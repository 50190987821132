
        import * as module0 from './controllers/confetti_controller.js';import * as module1 from './controllers/countdown_controller.js';import * as module2 from './controllers/figure_controller.js';import * as module3 from './controllers/gradient_controller.js';import * as module4 from './controllers/mobile_header_controller.js';import * as module5 from './controllers/plausible_controller.js';import * as module6 from './controllers/star_line_controller.js';import * as module7 from './controllers/toc_controller.js';import * as module8 from './controllers/utm_tracker_controller.js'
        const modules = {
            "./controllers/confetti_controller.js": module0,
            "./controllers/countdown_controller.js": module1,
            "./controllers/figure_controller.js": module2,
            "./controllers/gradient_controller.js": module3,
            "./controllers/mobile_header_controller.js": module4,
            "./controllers/plausible_controller.js": module5,
            "./controllers/star_line_controller.js": module6,
            "./controllers/toc_controller.js": module7,
            "./controllers/utm_tracker_controller.js": module8,
        };
        export default modules;
      