import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["heading", "anchor"]

	static values = {
		sizer: Number
	}

	highlight() {
		for (let heading of this.headingTargets) {
			const rect = heading.getBoundingClientRect()

			if (rect.top <= this.sizerValue) {
				this.anchorTargets.forEach(anchor => anchor.dataset.active = false)
				this.anchorTargets.find(anchor => anchor.dataset.id == heading.id).dataset.active = true
			}
		}
	}
}
