import { Controller } from "@hotwired/stimulus"
import confetti from "canvas-confetti"

export default class extends Controller {
	static targets = ["autoplay"]

	static values = {
		animation: {
			type: String,
			default: "basic",
		},
		delay: {
			type: Number,
			default: 0,
		},
		duration: {
			type: Number,
			default: 3,
		},
		count: {
			type: Number,
			default: 200,
		},
	}

	autoplayTargetConnected(element) {
		element.click()
	}

	async fire({ params: { animation, delay, duration, count } }) {
		delay = delay * 1000
		duration = duration * 1000

		await this.#delay(delay)

		switch (animation) {
			case "basic":
				this.#basic(count)
				break
			case "random":
				this.#random()
				break
			case "realistic":
				this.#realistic(count)
				break
			case "fireworks":
				this.#fireworks(duration, count)
				break
			case "snow":
				this.#snow(duration)
				break
			default:
				this.#basic(count)
				break
		}
	}

	#basic(count) {
		confetti({
			particleCount: count,
			spread: 70,
			origin: { y: 0.6 }
		})
	}

	#random() {
		confetti({
			angle: this.#randomInRange(55, 125),
			spread: this.#randomInRange(50, 70),
			particleCount: this.#randomInRange(50, 100),
			origin: { y: 0.6 }
		})
	}

	#realistic(count) {
		var defaults = {
			origin: { y: 0.7 },
		}

		function shoot(particleRatio, opts) {
			confetti({
				...defaults,
				...opts,
				particleCount: Math.floor(count * particleRatio),
			})
		}

		shoot(0.25, {
			spread: 26,
			startVelocity: 55,
		})
		shoot(0.2, {
			spread: 60,
		})
		shoot(0.35, {
			spread: 100,
			decay: 0.91,
			scalar: 0.8,
		})
		shoot(0.1, {
			spread: 120,
			startVelocity: 25,
			decay: 0.92,
			scalar: 1.2,
		})
		shoot(0.1, {
			spread: 120,
			startVelocity: 45,
		})
	}

	#fireworks(duration, count) {
		var animationEnd = Date.now() + duration
		var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 }

		var interval = setInterval(() => {
			var timeLeft = animationEnd - Date.now()

			if (timeLeft <= 0) {
				return clearInterval(interval)
			}

			var particleCount = count * (timeLeft / duration)
			confetti({
				...defaults,
				particleCount,
				origin: { x: this.#randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
			})
			confetti({
				...defaults,
				particleCount,
				origin: { x: this.#randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
			})
		}, 250)
	}

	#snow(duration) {
		const animationEnd = Date.now() + duration
		let skew = 1

		const frame = () => {
			const timeLeft = animationEnd - Date.now()
			const ticks = Math.max(200, 500 * (timeLeft / duration))

			skew = Math.max(0.8, skew - 0.001)

			confetti({
				particleCount: 5,
				startVelocity: 0,
				ticks: ticks,
				origin: {
					x: Math.random(),
					// since particles fall down, skew start toward the top
					y: Math.random() * skew - 0.2,
				},
				colors: ["#ffffff"],
				shapes: ["circle"],
				gravity: this.#randomInRange(0.4, 0.4),
				scalar: this.#randomInRange(0.2, 0.6),
				drift: this.#randomInRange(-0.4, 0.4),
			})

			if (timeLeft > 0) {
				requestAnimationFrame(frame)
			}
		}

		frame()
	}

	#delay(milliseconds) {
		return new Promise((resolve) => {
			setTimeout(resolve, milliseconds)
		})
	}

	#randomInRange(min, max) {
		return Math.random() * (max - min) + min;
	}
}
