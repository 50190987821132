import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static classes = ["zoomed"]

	static values = {
		zoom: {
			type: Boolean,
			default: false,
		}
	}

	zoom() {
		this.zoomValue = !this.zoomValue
	}

	zoomIn() {
		this.zoomValue = true
	}

	zoomOut() {
		this.zoomValue = false
	}

	zoomValueChanged() {
		if (this.zoomValue) {
			this.element.classList.add(this.zoomedClass)
			document.body.style.overflow = "hidden"
		} else {
			this.element.classList.remove(this.zoomedClass)
			document.body.style.overflow = null
		}
	}
}
