import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	utmKeys = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"]

	static targets = ["input"]

	static values = {
		namespace: String,
		keepAlive: Number
	}

	connect() {
		for (const key of this.utmKeys) {
			if (this.searchParams.get(key) != null) {
				this.removeFromStorage()
				this.saveIntoStorage()
				break
			}
		}

		for (const input of this.inputTargets) {
			this.setUtmTracker(input)
		}

		if (new Date() > this.keepAliveExpiration) {
			this.removeFromStorage()
		}
	}

	inputTargetConnected(input) {
		this.setUtmTracker(input)
	}

	saveIntoStorage() {
		const object = {
			keepAlive: new Date().toISOString()
		}

		for (const key of this.utmKeys) {
			const value = this.searchParams.get(key)

			if (value != null) {
				object[key] = value
			}
		}

		localStorage.setItem(this.storageKey, JSON.stringify(object))
	}

	removeFromStorage() {
		localStorage.removeItem(this.storageKey)
	}

	setUtmTracker(input) {
		const value = this.storageObject[input.name]

		if (value != null) {
			input.value = value
		}
	}

	get searchParams() {
		return new URL(window.location.href).searchParams
	}

	get storageKey() {
		return this.namespaceValue + "_utm"
	}

	get storageObject() {
		const object = localStorage.getItem(this.storageKey)
		return object != null ? JSON.parse(object) : null
	}

	get keepAlive() {
		const date = this.storageObject?.keepAlive
		return date != null ? new Date(date) : null
	}

	get keepAliveExpiration() {
		var date = this.keepAlive
		if (date == null) { return null }
		date.setDate(this.keepAlive.getDate() + this.keepAliveValue)
		return date
	}
}
